<template>
  <v-dialog v-model="$root.asterisk_dialog" max-width="750" light>
    <v-card>
      <v-card-title class="headline">Ajouter une information supplémentaire</v-card-title>

      <v-card-text>
        <v-textarea
          v-model="$root.invoice.details.text"
          type="text"
          rows="5"
          label="Information supplémentaire"
        />
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="$root.asterisk_dialog = false">Annuler</v-btn>
        <v-btn color="green" text @click="add_asterisk">Ajouter</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  methods: {
    /**
     * Add the information
     */
    add_asterisk: function() {
      // Disable modal
      this.$root.asterisk_dialog = false;

      // Enable detail
      this.$root.invoice.details.enabled = true;
    }
  }
};
</script>