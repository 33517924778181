<template>
  <v-dialog v-model="$root.line_dialog" max-width="750" light>
    <v-card>
      <v-card-title class="headline">Ajouter une ligne</v-card-title>

      <v-card-text>
        <v-textarea rows="5" v-model="$root.description" label="Description"></v-textarea>
        <v-text-field
          @keyup.native.enter="add_entry"
          v-model="$root.price"
          type="number"
          label="Prix (hors taxe)"
        />
        <v-text-field
          @keyup.native.enter="add_entry"
          v-model="$root.tva"
          type="number"
          label="TVA (en %)"
        />
        <span>
          Selection rapide:
          <v-btn color="green" @click="$root.tva = 5.5">5,5%</v-btn>&nbsp;
          <v-btn color="orange" @click="$root.tva = 10">10%</v-btn>&nbsp;
          <v-btn color="red" @click="$root.tva = 20">20%</v-btn>
        </span>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="$root.line_dialog = false">Annuler</v-btn>
        <v-btn color="green" text @click="add_entry">Ajouter la ligne</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  methods: {
    /**
     * Add a entry
     */
    add_entry: function() {
      // Disable the modal
      this.$root.line_dialog = false;

      // Get price
      let price = this.$root.price ? parseFloat(this.$root.price) : 0;

      // Insert the line
      this.$root.invoice.entries.push({
        name: this.$root.description.split("\n").join("<br />"),
        price: Math.round(price * 100) / 100,
        tva: Math.round(this.$root.tva * 100) / 100
      });

      // Reset temporary items
      this.$root.description = "";
      this.$root.price = 0;
      this.$root.tva = 10;

      // Update price
      this.$root.update_prices();
    }
  }
};
</script>