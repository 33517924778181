<template>
  <v-dialog v-model="$root.edit_dialog" max-width="750" light>
    <v-card>
      <v-card-title class="headline">Modifier une ligne</v-card-title>

      <v-card-text>
        <v-textarea rows="5" v-model="$root.description" label="Description"></v-textarea>
        <v-text-field
          v-model="$root.price"
          @keyup.native.enter="edit_item"
          type="number"
          label="Prix (hors taxe)"
        />
        <v-text-field v-model="$root.tva" type="number" label="TVA (en %)" />
        <span>
          Selection rapide:
          <v-btn color="green" @click="$root.tva = 5.5">5,5%</v-btn>&nbsp;
          <v-btn color="orange" @click="$root.tva = 10">10%</v-btn>&nbsp;
          <v-btn color="red" @click="$root.tva = 20">20%</v-btn>
        </span>
      </v-card-text>

      <v-card-actions>
        <v-btn color="red" text @click="delete_entry($root.in_use)">Supprimer la ligne</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="orange" text @click="$root.edit_dialog = false">Annuler</v-btn>
        <v-btn color="green" text @click="edit_item">Appliquer les modifications</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  methods: {
    // Delete an entry
    delete_entry: function(idx) {
      // Close modal
      this.$root.edit_dialog = false;

      // Remove price
      this.$root.invoice.amount.no_tax -= this.$root.invoice.entries[idx].price;

      // Delete entry
      if (idx > -1) {
        this.$root.invoice.entries.splice(idx, 1);
      }

      // Update price
      this.$root.update_prices();

      // Delete temporary variables
      this.$root.in_use = null;
      this.$root.description = null;
      this.$root.price = null;
      this.$root.tva = 10;

      // Force re-updating view
      this.$forceUpdate();
    },

    /**
     * Edit a entry
     */
    edit_item: function() {
      // Close modal
      this.$root.edit_dialog = false;

      // Set description
      this.$root.invoice.entries[
        this.$root.in_use
      ].name = this.$root.description.split("\n").join("<br />");

      // Set price
      this.$root.invoice.entries[this.$root.in_use].price =
        this.$root.price == null
          ? 0
          : Math.round(parseFloat(this.$root.price) * 100) / 100;

      // Set TVA
      this.$root.invoice.entries[this.$root.in_use].tva =
        Math.round(parseFloat(this.$root.tva) * 100) / 100;

      // Update prices
      this.$root.update_prices();

      // Delete temporary variables
      this.$root.in_use = null;
      this.$root.description = null;
      this.$root.price = null;
      this.$root.tva = 10;
    }
  }
};
</script>