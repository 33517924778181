<template>
  <v-dialog v-model="$root.promotion_dialog" max-width="450" light>
    <v-card>
      <v-card-title class="headline">Ajouter une réduction</v-card-title>

      <v-card-text>
        <v-text-field
          v-model="$root.invoice.promotion.percentage"
          type="number"
          label="Pourcentage de la réduction"
          @keyup.native.enter="add_promotion"
        />
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="$root.promotion_dialog = false">Annuler</v-btn>
        <v-btn color="green" text @click="add_promotion">Ajouter la réduction</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  methods: {
    /**
     * Add a promotion percentage
     */
    add_promotion: function() {
      // Disable the modal
      this.$root.promotion_dialog = false;

      // Check promotion value
      if (this.$root.invoice.promotion.percentage > 0) {
        // Enable promotion
        this.$root.invoice.promotion.enabled = true;
      } else {
        // Disable promotion
        this.$root.invoice.promotion.enabled = false;
      }

      // Update prices
      this.$root.update_prices();
    }
  }
};
</script>