<template>
  <div class="home">
    <div id="modals">
      <Acompte />
      <AddItem />
      <EditItem />
      <Asterisk />
      <Promotion />
      <Changelog />
    </div>
    <Drawer />
    <v-main>
      <Preview />
      <v-snackbar v-model="snackbar">
        {{ message }}
        <v-btn color="red" text @click="snackbar = false">Fermer</v-btn>
      </v-snackbar>
    </v-main>
    <Footer />
  </div>
</template>

<script>
import Acompte from "@/components/modals/Acompte.vue";
import AddItem from "@/components/modals/AddItem.vue";
import EditItem from "@/components/modals/EditItem.vue";
import Asterisk from "@/components/modals/Asterisk.vue";
import Promotion from "@/components/modals/Promotion.vue";
import Changelog from "@/components/modals/Changelog.vue";
import Drawer from "@/components/Drawer.vue";
import Preview from "@/components/Preview.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Home",
  components: {
    Acompte,
    AddItem,
    EditItem,
    Asterisk,
    Promotion,
    Changelog,
    Drawer,
    Preview,
    Footer
  },
  data: () => {
    return {
      snackbar: false,
      message: ""
    };
  },
  mounted() {
    // Fetch invoices
    if (localStorage.invoice) {
      // Assign
      Object.assign(this.$root.invoice, JSON.parse(localStorage.invoice));

      // Check version
      if (this.$root.invoice.version < 1.2) {
        // Display message
        this.message = `Le fichier ouvert a été crée sur une version antérieure à la version 1.2.
          Certains prix peuvent être incorrects.`;
        this.snackbar = true;

        // Update version
        this.$root.invoice.version = 1.2;
      }

      // Update prices
      this.$root.update_prices();
    }

    // Fetch settings
    if (localStorage.settings) {
      Object.assign(this.$root.settings, JSON.parse(localStorage.settings));
    }
  }
};
</script>
