import jsPDF from "jspdf"
import autoTable from "jspdf-autotable"
import { LOGO } from "../assets/logo"

const ACCCENT_COLOR = "#6366F1"

export const printInvoice = async (invoice) => {
  const doc = new jsPDF()
  doc.setFont("Helvetica")

  // Entries
  const lines = []
  for (const field of invoice.entries) {
    const total = field.price * (1 + field.tva / 100)

    const line = [
      field.name.replaceAll("<br />", "\n"),
      field.price + " €",
      field.tva + " %",
      `${Math.round(total * 100) / 100} €`,
    ]

    lines.push(line)
  }

  console.log(lines)

  // Get footer size
  const footer = [[""], [""], [""]]

  if (invoice.promotion.enabled || invoice.acompte.enabled) {
    footer.push([""])
  }

  // Generate table
  autoTable(doc, {
    body: lines,
    foot: footer,
    didDrawPage: () => {
      // Draw components on every pages
      drawHeader(doc, invoice)
      drawCustomerHeader(doc, invoice)
      drawFooter(doc)
    },
    headStyles: {
      fillColor: "#fff",
      textColor: "#6B7280",
      cellPadding: {
        horizontal: 7,
        vertical: 5,
      },
      lineWidth: 0.1,
      lineColor: [229, 231, 235],
    },
    bodyStyles: {
      cellPadding: {
        horizontal: 5,
        vertical: 3,
      },
    },
    rowPageBreak: "avoid",
    columns: [
      { dataKey: "description", header: "Description" },
      { dataKey: "price", header: "Prix HT" },
      { dataKey: "tax", header: "TVA" },
      { dataKey: "total", header: "Prix TTC" },
    ],
    columnStyles: {
      price: {
        halign: "right",
        cellWidth: 35,
      },
      tax: {
        halign: "right",
        cellWidth: 25,
      },
      total: {
        halign: "right",
        cellWidth: 35,
      },
    },
    showFoot: "lastPage",
    footStyles: {
      fillColor: [255, 255, 255],
      textColor: [255, 255, 255],
    },
    theme: "striped",
    margin: {
      top: 93,
      left: 0,
      right: 0,
      bottom: 40,
    },
  })

  drawPriceFooter(doc, invoice)

  // Draw notes
  if (invoice.details.enabled) {
    drawNotes(doc, invoice)
  }

  drawLastPage(doc, invoice)

  doc.output("dataurlnewwindow")
}

const drawHeader = (doc, invoice) => {
  const startY = 42

  doc
    .setFillColor(249, 250, 251)
    .rect(0, 0, 210, 70, "F")
    .addImage(LOGO, "JPEG", 15, 10, 25, 25)
    .setFontSize(16)
    .setTextColor(ACCCENT_COLOR)
    .setFont("Helvetica", "Bold")
    .text("Happy Chauffe & Co.", 15, startY + 2)
    .setFont("Helvetica", "normal")
    .setFontSize(12)
    .setTextColor(107, 114, 128)
    .text(
      ["contact@happychauffe.fr", "+33 06 67 22 88 08", "www.happychauffe.fr"],
      15,
      startY + 8,
      { lineHeightFactor: 1.4 }
    )
    .setFontSize(10)
    .setTextColor(ACCCENT_COLOR)
    .setFont("Helvetica", "Bold")
    .text(invoice.type.toUpperCase(), 195, 15, { align: "right" })
    .setFont("Helvetica", "normal")
    .setFontSize(14)
    .setTextColor(107, 114, 128)
    .text(invoice.number, 195, 21, { align: "right" })
    .setFontSize(10)
    .setTextColor(ACCCENT_COLOR)
    .setFont("Helvetica", "Bold")
    .text("DATE", 195, 30, { align: "right" })
    .setFont("Helvetica", "normal")
    .setFontSize(14)
    .setTextColor(107, 114, 128)
    .text(invoice.date, 195, 36, { align: "right" })
}

const drawCustomerHeader = (doc, invoice) => {
  const startY = 70

  doc
    .setFillColor(229, 231, 235)
    .rect(0, startY, 210, 23, "F")
    .setFont("Helvetica", "normal", 700)
    .setFontSize(12)
    .setTextColor(107, 114, 128)
    .text(invoice.client.name, 15, startY + 10)
    .setFont("Helvetica", "normal")
    .setFontSize(11)
    .text(invoice.client.address, 15, startY + 16, {
      lineHeightFactor: 1.6,
    })
}

const drawPriceFooter = (doc, invoice) => {
  const text = [
    ["Total HT", `${invoice.amount.no_tax} €`],
    ["TVA", `${invoice.amount.tax} €`],
  ]

  let startY = 233

  // Check for promotion
  if (invoice.promotion.enabled) {
    text.splice(text.length, 0, [
      "Réduction",
      `- ${invoice.promotion.amount > 0
        ? invoice.promotion.amount + " €"
        : invoice.promotion.percentage + " %"
      }`,
    ])

    startY -= 6
  }

  // Check for deposit
  if (invoice.acompte.enabled) {
    text.splice(text.length, 0, ["Acompte", `- ${invoice.acompte.amount} €`])

    startY -= 6
  }

  autoTable(doc, {
    body: text,
    foot: [["Total TTC", `${parseFloat(invoice.amount.total - invoice.acompte.amount).toFixed(2).toLocaleString()} €`]],
    theme: "plain",
    startY,
    styles: {
      halign: "right",
      cellPadding: {
        vertical: 1.25,
        right: 1.8,
      },
      lineWidth: 0.1,
      lineColor: [229, 229, 229],
      cellWidth: 30,
    },
    bodyStyles: {
      fontSize: 10,
    },
    footStyles: {
      fontSize: 11,
      font: "Helvetica",
      fontStyle: "Bold",
    },
    margin: {
      top: 0,
      left: 135,
      right: 15,
      bottom: 0,
    },
  })
}

/**
 *
 * @param { jsPDF } doc
 * @param {*} invoice
 */
const drawNotes = (doc, invoice) => {
  if (invoice.details.text.length > 300) {
    doc.addPage()
    drawHeader(doc, invoice)
    drawFooter(doc)

    doc
      .setFont("Helvetica", "normal")
      .setFontSize(10)
      .setTextColor(107, 114, 128)
      .text(invoice.details.text, 15, 80, {
        maxWidth: 210 - 15 - 15,
      })
  } else {
    doc
      .setFont("Helvetica", "normal")
      .setFontSize(9)
      .setTextColor(107, 114, 128)
      .text(invoice.details.text, 15, 233, {
        maxWidth: 110,
      })
  }
}

/**
 * Draw last page
 *
 * @param { jsPDF } doc
 * @param {*} invoice
 */
const drawLastPage = (doc, invoice) => {
  // Don't create a page if everything is unchecked
  if (Object.values(invoice.display).every((v) => v === false)) {
    return
  }

  doc.addPage()

  drawHeader(doc, invoice)
  drawFooter(doc)

  let start = 80

  if (invoice.display.attestation) {
    drawCertificate(doc, start)
    start += 35
  }

  if (invoice.display.payment) {
    drawPaymentMethod(doc, start)
    start += 20
  }

  if (invoice.display.signature) {
    drawSignature(doc, start)
    start += 35
  }

  if (invoice.display.date) {
    drawDate(doc, start)
    start += 20
  }
}

/**
 * Draw certificate
 *
 * @param { jsPDF } doc
 */
const drawCertificate = (doc, start) => {
  doc
    .setFontSize(11)
    .setFont("Helvetica", "Bold")
    .setTextColor(ACCCENT_COLOR)
    .text("Attestation de TVA à 10%", 210 / 2, start, { align: "center" })
    .setTextColor(107, 114, 128)
    .setFontSize(9)
    .setFont("Helvetica", "normal")
    .text(
      [
        "Je soussigné ________________ domicilié à l'adresse ci-dessus",
        "atteste que mon domicile ou local à usage d'habitation ci-dessus dont je suis:",
        "    Propriétaire            Locataire",
        "est achevé depuis plus de deux ans.",
      ],
      210 / 2,
      start + 6,
      {
        align: "center",
        lineHeightFactor: 1.5,
      }
    )
    .rect(82, start + 13, 3, 3, "S")
    .rect(109, start + 13, 3, 3, "S")
}

/**
 *
 * @param { jsPDF } doc
 * @param {*} invoice
 */
const drawSignature = (doc, start) => {
  doc
    .setFontSize(11)
    .setFont("Helvetica", "Bold")
    .setTextColor(ACCCENT_COLOR)
    .text("Signature", 210 / 2, start, { align: "center" })
    .setTextColor(107, 114, 128)
    .setFontSize(9)
    .setFont("Helvetica", "normal")
    .text(
      [
        "Signature du propriétaire                           Signature de l'entreprise",
      ],
      210 / 2,
      start + 6,
      {
        align: "center",
      }
    )
}

/**
 *
 * @param { jsPDF } doc
 * @param {*} invoice
 */
const drawDate = (doc, start) => {
  doc.text(["Le: ______ / ______ / ______"], 210 / 2, start, {
    align: "center",
  })
}

/**
 *
 * @param { jsPDF } doc
 * @param {*} invoice
 */
const drawPaymentMethod = (doc, start) => {
  doc
    .setFontSize(11)
    .setFont("Helvetica", "Bold")
    .setTextColor(ACCCENT_COLOR)
    .text("Méthode de paiement", 210 / 2, start, { align: "center" })
    .setTextColor(107, 114, 128)
    .setFontSize(9)
    .setFont("Helvetica", "normal")
    .text("Chèque         Virement", 210 / 2, start + 6, {
      align: "center",
    })
    .rect(85, start + 3.5, 3, 3, "S")
    .rect(104, start + 3.5, 3, 3, "S")
}

const drawFooter = (doc) => {
  const informations = [
    "SIRET 87802561800017 RCS Lille Métropole",
    "Code APE 4322A",
    "TVA INTRA FR/59 878025618",
    "RC Generali AT06063",
    `Devis valable 30 jours. Le taux de TVA étant fixé de manière législative, seul le prix hors taxes est contractuel. Le taux de TVA qui vous sera appliqué est donc celui en vigueur au jour de la facturation. Les marchandises et main-d'oeuvre restent notre propriété jusqu'au paiement intégral par l'acheteur.`,
  ]

  doc
    .setFillColor(249, 250, 261)
    .rect(0, 258, 210, 40, "F")
    .setFontSize(10)
    .setTextColor(ACCCENT_COLOR)
    .setFont("Helvetica", "Bold")
    .text("SAS Happy Chauffe & Co.".toUpperCase(), 15, 267)
    .setFont("Helvetica", "normal")
    .setFontSize(10)
    .setTextColor(107, 114, 128)
    .text(
      [
        "Tel.: +33 06 67 22 88 08",
        "Email: contact@happychauffe.fr",
        "148 avenue de l'hippodrome",
        "59130 Lambersart, France",
      ],
      15,
      273,
      {
        lineHeightFactor: 1.5,
      }
    )
    .setFontSize(10)
    .setTextColor(ACCCENT_COLOR)
    .setFont("Helvetica", "Bold")
    .text("INFORMATIONS", 195, 267, { align: "right" })
    .setFont("Helvetica", "normal")
    .setFontSize(8)
    .setTextColor(107, 114, 128)
    .text(informations.join(" • "), 195, 272, {
      align: "right",
      maxWidth: 100,
      lineHeightFactor: 1.4,
    })
}
