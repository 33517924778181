<template>
  <v-navigation-drawer :width="400" :light="$root.settings.is_light" app clipped permanent right>
    <v-list nav dense>
      <v-subheader>Options du fichier</v-subheader>
      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-list-item link v-on="on" @click="new_file">
            <v-list-item-icon>
              <v-icon>mdi-file-plus</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Nouveau fichier</v-list-item-title>
              <v-list-item-subtitle>Enregistrez le travail en cours avant !</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
        <span>N'oubliez pas d'enregistrer votre facture en cours, celle-ci sera perdue !</span>
      </v-tooltip>
      <input type="file" accept=".json" ref="inputUpload" @change="load_file" hidden />
      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <v-list-item link @click="$refs.inputUpload.click()" v-on="on">
            <v-list-item-icon>
              <v-icon>mdi-file-find</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Charger un fichier</v-list-item-title>
              <v-list-item-subtitle>Ouvrir une facture JSON</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
        <span>N'oubliez pas d'enregistrer votre facture en cours, celle-ci sera perdue !</span>
      </v-tooltip>
      <v-list-item link @click="save_file">
        <v-list-item-icon>
          <v-icon>mdi-file-send</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Sauvegarder le fichier</v-list-item-title>
          <v-list-item-subtitle>Enregistrer le fichier JSON</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item link @click="print_doc">
        <v-list-item-icon>
          <v-icon>mdi-printer</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Imprimer le fichier</v-list-item-title>
          <v-list-item-subtitle>Ou enregistrer le fichier PDF</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider />
    <v-container>
      <v-radio-group label="Type de fichier" v-model="$root.invoice.type" :mandatory="true">
        <v-radio color="green" label="Devis" value="Devis" />
        <v-radio color="blue" label="Facture" value="Facture" />
      </v-radio-group>
      <v-text-field label="Numéro du fichier" v-model="$root.invoice.number" placeholder="XX/2020" />
      <v-text-field label="Date du fichier" v-model="$root.invoice.date" />
      <v-text-field label="Nom du client" v-model="$root.invoice.client.name" />
      <v-text-field
        label="Adresse du client"
        type="search"
        v-model="$root.invoice.client.address"
        id="address_search"
      />
    </v-container>
    <v-divider />
    <v-list nav dense>
      <v-subheader>Gestion des prix</v-subheader>
      <v-list-item link @click.stop="$root.line_dialog = true">
        <v-list-item-icon>
          <v-icon>mdi-playlist-plus</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Ajouter un objet/prix</v-list-item-title>
          <v-list-item-subtitle>Ajoute une ligne avec un prix et une TVA</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item link @click.stop="$root.promotion_dialog = true">
        <v-list-item-icon>
          <v-icon>mdi-brightness-percent</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Appliquer une reduction</v-list-item-title>
          <v-list-item-subtitle>Pourcentage de réduction sur le prix TTC</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item link @click.stop="$root.acompte_dialog = true">
        <v-list-item-icon>
          <v-icon>mdi-cash-usd</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Ajouter un acompte</v-list-item-title>
          <v-list-item-subtitle>Acompte déduis du prix total TTC</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider />
    <v-list nav dense>
      <v-subheader>Options supplémentaires</v-subheader>
      <v-list-item link @click.stop="$root.asterisk_dialog = true">
        <v-list-item-icon>
          <v-icon>mdi-asterisk</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Ajouter une information en asterisk</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item link @click.stop="$root.update_prices()">
        <v-list-item-icon>
          <v-icon>mdi-history</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Forcer le re-calcul des prix</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-action>
          <v-checkbox color="green" v-model="$root.invoice.display.payment" />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Afficher les options de paiement</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-action>
          <v-checkbox color="green" v-model="$root.invoice.display.date" />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Afficher la date à remplir</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-action>
          <v-checkbox color="green" v-model="$root.invoice.display.attestation" />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Afficher l'attestation de 10%</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-action>
          <v-checkbox color="green" v-model="$root.invoice.display.signature" />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Afficher les signatures à remplir</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import places from "places.js";
import { saveAs } from "file-saver";
import { printInvoice } from "../logic/printing";

export default {
  mounted() {
    // Start place listener
    let placeAutoComplete = places({
      appId: "plUOU60MK0PK",
      apiKey: "9f131b5bd78cc10eceef9b90f30a9aa2",
      container: document.querySelector("#address_search")
    });

    // Catch changes
    placeAutoComplete.on("change", e => {
      // Set model
      this.$root.invoice.client.address = `${e.suggestion.name}, ${e.suggestion.postcode} ${e.suggestion.city}`;
    });
  },
  methods: {
    /**
     * Create new file
     */
    new_file: function () {
      // Delete local storage
      delete localStorage.invoice;

      // Reload page
      window.location.reload(true);
    },

    /**
     * Print document
     */
    print_doc: function () {
      printInvoice(this.$root.invoice);
    },

    /**
     * Load a file from file reader
     */
    load_file: function () {
      // Instance a file reader
      var reader = new FileReader();

      // Handle file
      reader.onload = function (event) {
        // Parse JSON
        const data = JSON.parse(event.target.result);

        // Get the TVA
        const tva =
          Math.round(((data.amount.tax * 100) / data.amount.no_tax) * 100) /
          100;

        // Apply TVA if unset
        data.entries.forEach(entry => {
          if (!("tva" in entry)) {
            // Fix TVA
            entry.tva = tva;

            // Notify old version
            data.version = 1.1;
          }
        });

        // Save in localStorage
        localStorage.invoice = JSON.stringify(data);

        // Reload page
        window.location.reload(true);
      };

      // Read as text
      reader.readAsText(event.target.files[0]);
    },
    save_file: function () {
      // Generate file
      var blob = new Blob([JSON.stringify(this.$root.invoice)], {
        type: "application/json;charset=utf-8"
      });

      // Start saving
      saveAs(blob, this.$root.download.name);
    }
  }
};
</script>