<template>
  <v-container class="pre-preview">
    <div id="preview">
      <div class="page-header">
        <img src="../assets/header.png" />
      </div>

      <div class="page-footer">
        <img src="../assets/footer.png" />
      </div>

      <table>
        <thead>
          <tr>
            <td>
              <div class="page-header-space"></div>
            </td>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <div class="content">
                <v-row class="header-text">
                  <v-col>
                    <h2>
                      <b>{{ $root.invoice.type }} n°{{ $root.invoice.number }}</b>
                    </h2>
                    <h4>Date: {{ $root.invoice.date }}</h4>
                  </v-col>
                  <v-col class="text-right">
                    <h2>
                      <b>{{ $root.invoice.client.name }}</b>
                    </h2>
                    <h4>{{ $root.invoice.client.address }}</h4>
                  </v-col>
                </v-row>
                <br />
                <v-simple-table light>
                  <thead>
                    <tr>
                      <th class="text-center" :width="10">#</th>
                      <th class="text-left">Description</th>
                      <th class="text-right actions" :width="100">Actions</th>
                      <th class="text-right" :width="110">Total HT</th>
                      <th class="text-right" :width="25">TVA</th>
                      <th class="text-right" :width="100">Montant TVA</th>
                      <th class="text-right" :width="125">Total TTC</th>
                    </tr>
                  </thead>
                  <tbody>
                    <v-tooltip top v-for="(entry, idx) in $root.invoice.entries" :key="idx">
                      <template v-slot:activator="{ on }">
                        <tr v-on="on">
                          <td>{{ idx + 1 }}</td>
                          <td
                            @click="start_editing(idx)"
                            class="description-block"
                            v-html="entry.name"
                          />
                          <td class="actions text-right">
                            <v-btn text icon @click="move_up(idx)">
                              <v-icon>mdi-arrow-up</v-icon>
                            </v-btn>
                            <v-btn text icon @click="move_down(idx)">
                              <v-icon>mdi-arrow-down</v-icon>
                            </v-btn>
                          </td>
                          <td
                            @click="start_editing(idx)"
                            align="right"
                          >{{ Math.round(entry.price * 100) / 100 }} €</td>
                          <td @click="start_editing(idx)" align="right">{{ entry.tva }}%</td>
                          <td
                            @click="start_editing(idx)"
                            align="right"
                          >{{ Math.round((entry.price * (1 + entry.tva / 100) - entry.price) * 100) / 100 }} €</td>
                          <td
                            @click="start_editing(idx)"
                            align="right"
                          >{{ Math.round((entry.price * (1 + entry.tva / 100)) * 100) / 100 }} €</td>
                        </tr>
                      </template>
                      <p align="center">Cliquez sur la ligne pour la modifier ou supprimer celle-ci.</p>
                    </v-tooltip>
                  </tbody>
                </v-simple-table>
                <br />
                <div class="final-price-block">
                  <v-row>
                    <v-col cols="7">
                      <v-tooltip top v-if="$root.invoice.details.enabled">
                        <template v-slot:activator="{ on }">
                          <v-label light @click="remove_asterisk" v-on="on">
                            <span
                              v-for="(line, idx) in $root.invoice.details.text.split('\n')"
                              :key="idx"
                            >
                              {{ line }}
                              <br />
                            </span>
                          </v-label>
                        </template>
                        <span>Cliquez pour supprimer le texte</span>
                      </v-tooltip>
                    </v-col>
                    <v-col cols="5">
                      <v-simple-table dark class="final-price">
                        <tbody>
                          <tr>
                            <td>Prix HT</td>
                            <td align="right">{{ $root.invoice.amount.no_tax }} €</td>
                          </tr>
                          <tr>
                            <td>TVA</td>
                            <td align="right">{{ $root.invoice.amount.tax }} €</td>
                          </tr>
                          <v-tooltip v-if="$root.invoice.promotion.enabled" top>
                            <template v-slot:activator="{ on }">
                              <tr @click="remove_promotion" v-on="on">
                                <td>Réduction de {{ $root.invoice.promotion.percentage }}%</td>
                                <td align="right">-{{ $root.invoice.promotion.amount }} €</td>
                              </tr>
                            </template>
                            <span>Cliquez sur la ligne pour la promotion</span>
                          </v-tooltip>
                          <tr>
                            <td>Total TTC</td>
                            <td align="right">{{ $root.invoice.amount.total }} €</td>
                          </tr>
                          <v-tooltip v-if="$root.invoice.acompte.enabled" top>
                            <template v-slot:activator="{ on }">
                              <tr
                                @click="$root.invoice.acompte.amount = 0 ; $root.invoice.acompte.enabled = false"
                                v-on="on"
                              >
                                <td>Acompte</td>
                                <td align="right">-{{ $root.invoice.acompte.amount }} €</td>
                              </tr>
                            </template>
                            <span>Cliquez sur la ligne pour l'acompte</span>
                          </v-tooltip>
                          <tr v-if="$root.invoice.acompte.enabled">
                            <td>Reste à payer</td>
                            <td
                              align="right"
                            >{{ parseFloat($root.invoice.amount.total - $root.invoice.acompte.amount).toFixed(2).toLocaleString() }} €</td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </div>
                <br />
                <div class="information text-center">
                  <br />
                  <div v-if="$root.invoice.display.payment" class="inline">
                    <u>Règlement :</u>
                    <v-checkbox light label="Chèque" />
                    <v-checkbox light label="Virement" />
                  </div>
                  <br />
                  <div v-if="$root.invoice.display.date">
                    Le :
                    <div class="hand-fill-small" />/
                    <span class="hand-fill-small" /> / {{ (new Date()).getUTCFullYear() }}
                  </div>
                  <br />
                  <div v-if="$root.invoice.display.attestation">
                    <u>Attestation de TVA à 10%</u>
                    <br />Je soussigné
                    <div class="hand-fill-big" />domicilié à l'adresse ci-dessus, atteste que mon domicile ou local
                    à usage d'habitation ci-dessus dont je suis :
                    <div class="inline">
                      <v-checkbox light label="Propriétaire" />
                      <v-checkbox light label="Locataire" />
                    </div>est achevé depuis plus de deux ans.
                  </div>
                  <br />
                  <v-row v-if="$root.invoice.display.signature">
                    <v-col cols="6">
                      <u>Signature du propriétaire</u>
                    </v-col>
                    <v-col>
                      <u>Signature de l'entreprise</u>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </td>
          </tr>
        </tbody>

        <tfoot>
          <tr>
            <td>
              <div class="page-footer-space"></div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </v-container>
</template>

<style>
#preview {
  margin: 5%;
  border-radius: 10px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background: white;
  color: black;
  min-height: 100vh;
  padding-bottom: 17vh;
}
.ap-input {
  border: none !important;
}
.description-block {
  -ms-word-break: break-all;
  word-break: break-all;

  word-break: break-word;

  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.information {
  display: none;
  min-height: 30vh;
  break-before: always;
  break-inside: avoid;
  break-after: always;
}
.inline * {
  display: inline !important;
  padding: 3px;
  vertical-align: bottom;
}
.hand-fill-small {
  display: inline-block;
  width: 2cm;
  border-bottom: 1px solid black;
}
.hand-fill-big {
  display: inline-block;
  width: 4cm;
  border-bottom: 1px solid black;
}
#preview h2,
h4 {
  font-weight: 300;
}
.content,
table {
  width: 100% !important;
}
.content {
  padding: 2%;
  clear: both;
  break-after: always !important;
}
.final-price-block {
  display: block;
  break-after: always;
  break-inside: avoid;
  break-before: always;
}
.final-price tr {
  height: 3vh !important;
}
.page-footer {
  visibility: hidden;
  position: fixed;
  bottom: 0;
  width: 100%;
  background: white;
  display: block;
}
.page-header {
  display: block;
  background: white;
  visibility: hidden;
  position: fixed;
  top: 0;
  width: 100%;
}
.page-header img,
.page-footer img {
  height: 100%;
  width: 100%;
}
table {
  break-before: avoid;
  break-after: auto;
}
tr {
  break-inside: avoid;
  break-after: auto;
}
td {
  break-inside: avoid;
  break-after: auto;
}
thead {
  display: table-header-group;
}
tfoot {
  display: table-footer-group;
}
@media print {
  body {
    margin: 0 0 0 0;
  }
  body * {
    visibility: hidden;
  }
  .actions {
    display: none;
    visibility: hidden;
  }
  .information {
    display: block;
  }
  #preview,
  #preview * {
    visibility: visible;
  }
  #preview {
    margin: -3%;
    padding: 0;
    border-radius: 0;
    width: 100vw;
    min-height: 0;
  }
  .final-price {
    margin: 5%;
  }
  .page-footer,
  .page-header {
    visibility: visible;
  }
  .page-header,
  .page-header-space {
    height: 180px;
  }
  .page-footer,
  .page-footer-space {
    height: 180px;
  }
}
</style>

<script>
export default {
  methods: {
    /**
     * Start editing an item
     */
    start_editing: function(idx) {
      // Store the key as temporary
      this.$root.in_use = idx;

      // Set informations in temporary variables
      this.$root.description = this.$root.invoice.entries[idx].name;
      this.$root.price = this.$root.invoice.entries[idx].price;
      this.$root.tva = this.$root.invoice.entries[idx].tva;

      // Avoid line-break
      this.$root.description = this.$root.description
        .split("<br />")
        .join("\n");

      // Open the dialog
      this.$root.edit_dialog = true;
    },

    /**
     * Move an item up
     */
    move_up: function(idx) {
      // Move upper
      this.$root.invoice.entries.splice(
        idx - 1,
        0,
        this.$root.invoice.entries.splice(idx, 1)[0]
      );

      // Update view
      this.$forceUpdate();
    },

    /**
     * Move an item down
     */
    move_down: function(idx) {
      // Move down
      this.$root.invoice.entries.splice(
        idx + 1,
        0,
        this.$root.invoice.entries.splice(idx, 1)[0]
      );

      // Update vie
      this.$forceUpdate();
    },

    /**
     * Remove the promotion
     */
    remove_promotion: function() {
      // Disable the promotion
      this.$root.invoice.promotion.enabled = false;

      // Delete values
      this.$root.invoice.promotion.amount = 0;
      this.$root.invoice.promotion.percentage = 0;

      // Update prices
      this.$root.update_prices();
    },

    /**
     * Disable and remove informative text
     */
    remove_asterisk: function() {
      // Disable
      this.$root.invoice.details.enabled = false;

      // Delete text
      this.$root.invoice.details.text = null;
    }
  }
};
</script>
