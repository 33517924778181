import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import moment from "moment";

Vue.config.productionTip = false;

new Vue({
  data: {
    // Download container
    download: {
      name: null // Filename
    },

    // Invoice data
    invoice: {
      number: "", // Number/Index
      type: "Devis", // Type
      date: moment().format("DD/MM/YYYY"), // Date
      // Client Data
      client: {
        name: "", // Full name or company
        address: "" // Address
      },
      // Promotion
      promotion: {
        enabled: false, // On/Off
        percentage: 0, // Percentage of promotion
        amount: 0 // Total price
      },
      // Acompte
      acompte: {
        enabled: false, // On/off
        amount: 0 // Total price
      },
      // Total Amounbt
      amount: {
        no_tax: 0, // Without taxes
        tax: 0, // Taxes
        total: 0 // Total with taxes
      },
      // Additional text
      details: {
        enabled: false, // On/off
        text: "" // Text
      },
      // Additional options
      display: {
        payment: true, // Payment way
        date: true, // Date
        signature: true, // Sign
        attestation: true // Attestation
      },
      // Entries
      entries: []
    },

    // App settings
    settings: {
      is_light: false
    },

    // Temporary fields
    description: "",
    price: 0,
    tva: 10,

    // Temporary selector
    in_use: null,

    // Modals
    line_dialog: false,
    edit_dialog: false,
    promotion_dialog: false,
    asterisk_dialog: false,
    acompte_dialog: false,
    changelog_dialog: false,

    // Price updating method
    update_prices: function() {
      // Sum everything
      let no_tax = 0,
        tax = 0,
        total = 0;

      // Iterate every items
      this.$root.$data.invoice.entries.forEach(entry => {
        // Add price
        no_tax += entry.price;

        // Calculate TTC
        const ttc = entry.price * (1 + entry.tva / 100);

        // Set tax
        tax += ttc - entry.price;

        // Set total
        total += ttc;
      });

      // Check for promotion
      if (this.$root.$data.invoice.promotion.enabled) {
        // Get promotion amount in Euro
        this.$root.$data.invoice.promotion.amount = (total * (this.$root.$data.invoice.promotion.percentage / 100)).toFixed(2);

        // Update no tax amount
        total -= this.$root.$data.invoice.promotion.amount;
      }

      // Store prices
      this.$root.$data.invoice.amount = {
        no_tax: Math.round(no_tax * 100) / 100,
        tax: Math.round(tax * 100) / 100,
        total: Math.round(total * 100) / 100
      };
    }
  },
  watch: {
    invoice: {
      deep: true,
      handler() {
        // Stringify data
        var stringify = JSON.stringify(this.$root.$data.invoice);

        // Store to local storage
        if (localStorage) localStorage.invoice = stringify;

        // Setup download container
        this.$root.$data.download.name = `${
          this.$root.$data.invoice.type
        }-${this.$root.$data.invoice.number.replace("/", "-")}.json`;
      }
    },
    settings: {
      deep: true,
      handler() {
        // Store to local storage
        if (localStorage)
          localStorage.settings = JSON.stringify(this.$root.$data.settings);
      }
    }
  },
  router,
  vuetify,
  render: h => h(App)
}).$mount("#app");
