var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-footer',{staticClass:"footer",attrs:{"app":""}},[_c('span',[_c('v-btn',{attrs:{"text":"","small":""},on:{"click":function($event){_vm.$root.changelog_dialog = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-history")]),_vm._v("Version 1.2.3 ")],1)],1),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"padded",attrs:{"icon":"","small":""},on:{"click":function($event){_vm.$root.line_dialog = true}}},on),[_c('v-icon',[_vm._v("mdi-playlist-plus")])],1)]}}])},[_c('span',[_vm._v("Ajouter une ligne")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"padded",attrs:{"icon":"","small":""},on:{"click":function($event){_vm.$root.promotion_dialog = true}}},on),[_c('v-icon',[_vm._v("mdi-brightness-percent")])],1)]}}])},[_c('span',[_vm._v("Appliquer une réduction")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"padded",attrs:{"icon":"","small":""},on:{"click":function($event){_vm.$root.acompte_dialog = true}}},on),[_c('v-icon',[_vm._v("mdi-cash-usd")])],1)]}}])},[_c('span',[_vm._v("Ajouter un acompte")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":""},on:{"click":function($event){_vm.$root.settings.is_light = !_vm.$root.settings.is_light}}},on),[_c('v-icon',[_vm._v("mdi-brightness-6")])],1)]}}])},[_c('span',[_vm._v("Changer le theme de l'application")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }